
import { defineComponent, ref } from "vue";
import XLSX from "xlsx";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "surveys",
    components: {},
    data: function () {
        return {
            users: [],
            surveys: [
                {
                    label: "Enquête test 1",
                    date_begin: "01/01/2022",
                    date_end: "01/03/2022",
                    langues: "English - Français",
                },
            ],
            filter: {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            },
            userFileData: [],
            loading: true,
        };
    },
    methods: {
        resetFilter() {
            this.filter = {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            };
            this.fetchUsers();
        },
        fetchUsers() {
            this.loading = true;
            userApi
                .getUsers(this.filter)
                .then((response) => {
                    this.users = response.data["users"];
                    this.loading = false;
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des utilisateurs.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },

    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.surveys.title"), []);
        const router = useRouter();
        const submitButton = ref<HTMLElement | null>(null);

        function editUser(user: any) {
            userApi.getSpecificUser(user.id).then(() => {
                router.push({ name: "usersdetail", params: { user_id: user.id }, query: { admin: "false" } });
            });
        }

        return { t, editUser, submitButton };
    },
    created() {
        this.fetchUsers();
    },
});
